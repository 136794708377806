<template>
  <v-card elevation="0" class="cardWrapper pt-5 pb-8 px-3" rounded max-width="100%" min-width="320px">
    <v-card-title class="cardTitle pa-0 justify-center"
      >Document Key</v-card-title
    >
    <v-simple-table>
      <template v-slot:default>
        <tbody class="tBody">
          <tr v-for="item in items" :key="item.name">
            <td>{{ item.name }}</td>
            <td>{{ item.text }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
export default {
  name: "DocumentCard",
  data() {
    return {
      items: [
        {
          name: "2d",
          text: "Engineering & design package",
        },
        {
          name: "Close Out Report",
          text: "Project installation with photographs",
        },
        {
          name: "Invoice",
          text: "Installer payment on substantial completion",
        },
        {
          name: "M1",
          text: "Verification of substantial completion",
        },
        {
          name: "NEM",
          text: "Utility interconnection agreement",
        },
        {
          name: "PPA",
          text: "Prepaid Power Purchase Agreement with host customer",
        },
        {
          name: "SOW",
          text: "Approved technical scope of work",
        },
        {
          name: "Title",
          text: "Property search",
        },
        {
          name: "UB",
          text: "Utility bill",
        },
      ],
    };
  },
};
</script>

<style scoped>
.cardWrapper {
  box-shadow: 0px 7px 5px rgba(0, 0, 0, 0.25) !important;
}
.cardTitle {
  font-family: "Inter";
  font-weight: 400;
  font-size: 20px !important;
  text-transform: uppercase;
  color: #000000;
  padding-bottom: 10px !important;
}
.tBody tr td {
  font-family: "Inter";
  font-weight: 400 !important;
  font-size: 17px !important;
  text-transform: capitalize;
  color: #000000;
  border: 1px solid #dad9d9;
  height: 27px !important;
  padding: 0 6px !important;
}
.tBody tr:hover {
  background: #ffffff !important;
}
</style>