<template>
  <v-app 
  id = "wallpaper" 
  style="background-color: #719dc7; 
  padding-top: 20px;"
  >
       
    <div class="d-flex align-center" style="padding-left: 3%;">

        <!-- company logo -->
        <a href="https://www.inception.financial#howitworks">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="//images.squarespace-cdn.com/content/v1/6018b3b42273d3569232bc79/1613011159112-72HUAC7QOUWOAR422W2N/Inception+Financial+Logo%404x.png?format=1500w"
          transition="scale-transition"
          max-width="300px" 
        />
      </a>

        <!-- bar buttons -->
        <v-col style="display: flex; justify-content: flex-end; padding-right: 70px;">
        <a class="d-flex align-center" href = "https://www.inception.financial#benefits" style="margin-right: 20px; color: black !important;">
          BENEFITS
        </a>
        <a class="d-flex align-center" href = "https://www.inception.financial#howitworks" style="margin-right: 20px; color: black !important;">
          HOW IT WORKS
        </a>
        <a class="d-flex align-center" href = "https://www.inception.financial#FAQs" style="margin-right: 20px; color: black !important;">
          FAQs
        </a>
        <a class="d-flex align-center" href = "https://www.inception.financial#team" style="margin-right: 20px; color: black !important;">
          TEAM
        </a>
        <a class="d-flex align-center" href = "/investors" style="margin-right: 20px; color: black !important;">
          INVESTORS
        </a>
        <v-btn class="LEARMORE d-flex align-center" style="background-color:#97b464; color: white !important; padding: 23px; padding-left: 25px; padding-right: 25px;" href = "https://www.inception.financial/general-2-1">
          LEARN MORE
        </v-btn>
      </v-col>
      </div>
      
      <!-- insert the data -->
      <div>
        <v-col style="display: flex; justify-content: center; padding-top: 100px;">
          <p style="margin-right: 499px !important; margin-bottom: -5px;">New Password</p>
        </v-col>

        <v-form ref="forms">

        <v-col style="display: flex; justify-content: center;">
           <!-- Text field password -->
           <v-text-field
            v-model="password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min, rules.CapLet, rules.SpecialCar, rules.num]"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            counter
            class="imp"
            solo
            hide-details="true"
            @click:append="show1 = !show1"
            maxlength="16"
          ></v-text-field>
        </v-col>

        <v-col class="p" style="display: flex; justify-content: center; padding-top: 30px;">
          <p style="margin-right: 483px !important; margin-bottom: -5px;">Confirm Password</p>
        </v-col>

        <v-col style="display: flex; justify-content: center;">
          <!-- Text field password -->
          <v-text-field
            v-model="password2"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show2 ? 'text' : 'password'"
            name="input-10-1"
            hide-details="true"
            solo
            counter
            class="imp"
            @click:append="show2 = !show2"
            maxlength="16"
            v-on:keyup.enter="need()"
          ></v-text-field>
        </v-col>
        </v-form>

        <v-col style="display: flex; justify-content: center;">
          <v-btn @click="need()"  :loading="waitingRes" style="background-color: #97b464; color: white; padding: 27px; letter-spacing: .19em; font-size: 1.1rem; margin-top: 5%;">
            CONFIRM
          </v-btn>
        </v-col>
      </div>
      <v-alert
          :value="alertMap.active"
          :color="alertMap.color"
          dark
          border="top"
          :icon="alertMap.icon"
          class="al"
          transition="scale-transition"
        >
        {{alertMap.content}}
      </v-alert>
  </v-app>
</template>

<script>
import axios from "axios";
import qs from "qs";
export default {
data(){
return     {
  alertMap:{
    active:false,
    color:"",
    icon:"mdi-alert-outline",
    content:""
  },
  email: 'testEmail',
  password:"",
  password2:"",
  show1: false,
  show2: false,
  waitingRes:false,
  // values necessary for password correct
  rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 8 || 'Min 8 characters',
          CapLet:(value) => (value && /[A-Z]{1}/.test(value)) || 'At least one capital latter',
          SpecialCar:(value) => (value && /[^A-Za-z0-9]/.test(value)) || 'At least one special character',
          num:(value) => (value && /\d/.test(value)) || 'At least one number',
        },
  }
},

async mounted(){
},

updated(){
    },

methods: {
  changeScreen(newValue){
      this.$store.commit("setCurrentView",newValue);
    },
  async need(){
    if (this.$refs.forms.validate()){
      this.alertMap.active = false;
      // types of alerts for different cases, for example, when the password is the same, when it is done correctly, missing symbols or characters.
      if (!!this.password && !!this.password2) {
        if (this.password == this.password2) {
          try {
            this.waitingRes = true;
            console.log(this.$store.state.sctoken);
            let updateRes = await axios.post('https://investorsportal-797836204.development.catalystserverless.com/server/investors_portal_function/updateEnd',qs.stringify({
              password: this.password,
              sc_token: this.$store.state.sctoken
            }));
            this.waitingRes = false;
            if(!!updateRes.data){              
                if(updateRes.data.message == "Password Updated Successfully"){
                  this.setAlertProper("green","mdi-checkbox-marked-circle","Password Updated");
                  this.waitingRes = true;
                  let windowURL = window.location.href.split('?')[0];
                  setTimeout(()=>{
                    window.location.replace(windowURL);
                  },4000) 
                }
                else if(!!updateRes.data.error){
                  if (updateRes.data.error.message == "jwt malformed") {
                    this.setAlertProper("pink","mdi-information","Token not valid"); 
                  }
                }
                else{
                    this.setAlertProper("pink","mdi-information",updateRes.data.message);          
                }                
            }
            console.log(updateRes);
          } catch (error) {
            console.log(error.data);
          }
        }
        else{
          this.setAlertProper("pink","mdi-information","The password is the same as the old password or does not match the new password");       
        }
      }
      else{
        this.setAlertProper("pink","mdi-cancel","Fill in all the boxes");
      }
    }
    else
    {
      this.setAlertProper("orange","mdi-alert-outline","It needs to have a symbol, capital letter or number.");
    }

  },
  setAlertProper(color,icon,content){
    this.alertMap= {
      'active':true,
      'color':color,
      'icon':icon,
      'content':content,
    }
    setTimeout(()=>{
      this.alertMap.active=false
    },15000)
  }
},

}
</script>

<style>
#wallpaper{
background-size:cover ;
background-image: url(https://images.squarespace-cdn.com/content/v1/6018b3b42273d3569232bc79/1616528555227-Q2VQEHVE9VA9B673YJAQ/blue%2Bbackground.001.jpg);
}

#wallpaper2{
  background-image: url(https://images.squarespace-cdn.com/content/v1/6018b3b42273d3569232bc79/1616528555227-Q2VQEHVE9VA9B673YJAQ/blue%2Bbackground.001.jpg);
}

.v-application a {
color: black !important;
}

a:link, a:visited {
text-decoration: none;
}

.form-control
{
margin-top: -30px;
}

.imp{
    max-width: 600px;
    background-color: white;
  }

  .theme--dark.v-sheet {
    color: #FFFFFF;
    justify-content: center;
    display: flex;
  }
</style>