<template>
<v-app id="wallpaper" style="background-color: #719dc7; 
    padding-top: 20px;">
    <div class="d-flex align-center" style="padding-left: 3%;">

        <!-- Company logo -->
        <a href="https://www.inception.financial#howitworks">
            <v-img alt="Vuetify Logo" class="shrink mr-2" contain src="//images.squarespace-cdn.com/content/v1/6018b3b42273d3569232bc79/1613011159112-72HUAC7QOUWOAR422W2N/Inception+Financial+Logo%404x.png?format=1500w" transition="scale-transition" max-width="300px" />
        </a>

        <!-- Bar buttons -->
        <!-- <v-col style="display: flex; justify-content: flex-end; padding-right: 70px;">
            <a class="d-flex align-center" href="https://www.inception.financial#benefits" style="margin-right: 20px; color: black !important;">
                BENEFITS
            </a>
            <a class="d-flex align-center" href="https://www.inception.financial#howitworks" style="margin-right: 20px; color: black !important;">
                HOW IT WORKS
            </a>
            <a class="d-flex align-center" href="https://www.inception.financial#FAQs" style="margin-right: 20px; color: black !important;">
                FAQs
            </a>
            <a class="d-flex align-center" href="https://www.inception.financial#team" style="margin-right: 20px; color: black !important;">
                TEAM
            </a>
            <a class="d-flex align-center" href="/investors" style="margin-right: 20px; color: black !important;">
                INVESTORS
            </a>
            <v-btn class="LEARMORE d-flex align-center" style="background-color:#97b464; color: white !important; padding: 23px; padding-left: 25px; padding-right: 25px;" href="https://www.inception.financial/general-2-1">
                LEARN MORE
            </v-btn>
        </v-col> -->
    </div>

    <!-- Insert the data -->
    <div style="height:70vh; color: white; display: flex; flex-direction: column; align-items:center;">
        <div v-if="!waitingLogin && isError !== true" style=" width:43%">
            <v-col class="p" style="display: flex; flex-direction: column; font-size: 2rem; padding-top: 30px;padding-bottom: 0px; margin-top: 4rem;">
                <p style="">Security Pin</p>
                <p style="font-size: 1.5rem; margin: 0; display: flex;">Hey&nbsp;<span style="color: #C5FF95; display: flex;">{{firstName || "User"}}</span>, Enter Your Pin</p>
            </v-col>

            <v-col style="display: flex; justify-content: center; margin: 0; padding-top:0; width: 100%">
                <v-text-field class="imp" placeholder="Enter Your Pin" v-model="zohoPin" type="text" solo hide-details="true"></v-text-field>
            </v-col>
            <v-col style="display: flex; justify-content: center; width:100%">
                <!-- Button the login  -->
                <v-btn @click="loginUser()" :loading="waitingLogin" style="background-color: #97b464; color: white; padding: 27px; letter-spacing: .19em; font-size: 1.1rem; margin-top: 20px;border-radius:1.5rem;">
                    LOGIN
                </v-btn>
            </v-col>
        </div>
        <div v-else-if="isError === true" style="display: flex; justify-content: center; align-items: center; height: 100%">
            <v-text style="color: red; font-size: 2.5rem;">{{errorMessage}}</v-text>
         </div>
        <div v-else style="display: flex; justify-content: center; align-items: center; height: 100%">
            <v-progress-circular indeterminate color="#97b464" style="width:50px; height: 50px;" ></v-progress-circular>
         </div>
    </div>

    <!-- A map has been created for the use of different descriptions of the alerts -->
    <v-alert :value="alertMap.active" :color="alertMap.color" dark border="top" icon="mdi-cancel" style="width: 40%; margin:auto" class="al" transition="scale-transition">
        {{alertMap.content}}
    </v-alert>
    <!-- footer -->
    <v-footer dark padless>
        <v-card id="wallpaper2" flat tile class="lighten-1 white--text" style="background-color: #719dc7 !important;">
            <v-row style="margin-left: -70px; margin-right: 40px;" class="mb-12 ms-0 me-0">
                <v-col style="display: flex; 
                flex-direction: row; ">
                    <v-card-text class="black--text pt-0" style=" padding-top: 50px !important; 
                  padding-left: 80px; 
                  display: flex;
                  justify-content: center;">
                        <p style=" 
                    line-height: normal; 
                    font-size: 1.5vw; 
                    padding-right: 350px;">
                            © 2020-2024 Inception Financial
                        </p>
                    </v-card-text>
                </v-col>

                <v-col style="margin-right: 0px; padding-right: 20; margin-left: -400px">
                    <v-card-text class="black--text pt-0" style=" padding-top: 30px !important; display: flex;">
                        <p>
                            DISCLAIMER
                            <br>
                            <br>
                            The information provided represents the opinion of Inception Financial and is not intended to be a forecast of future events or guarantee of future results. It is not intended to provide specific investment advice and should not be construed as an offering of securities or recommendation to invest nor should it be used as a primary basis of investment decisions. It is not to be construed to meet the needs of any particular investor, nor is it a representation or solicitation or an offer to sell/buy any security. Investors should consult with their investment professional for advice concerning their particular situation
                            <br>
                            <br>
                            Discussion of U.S. Federal Tax Implication of such investments is meant to be general in nature and does not discuss all aspects of U.S. Federal Income Taxation that may be relevant to a prospective Investor.</p>
                    </v-card-text>
                </v-col>
            </v-row>
        </v-card>
    </v-footer>

</v-app>
</template>

<script>
import axios from "axios";
import qs from "qs";

export default {
    created() {
        this.alertMap.active = false;
        const url = new URLSearchParams(window.location.search)
        const id = url.get("id")
        if(id){
            this.zohoId = id
            this.verifyUser(id)
        }
        else{
            this.isError = true;
            this.errorMessage = "Invalid Url"
        }
    },
    data() {
        return {
            zohoPin: null,
            zohoId: null,
            firstName: null,
            loginUserData: null,
            waitingLogin: false,
            isError: false,
            errorMessage: null,
            alertMap: {
                active: false,
                color: "pink",
                content: "Fill in all the boxes"
            },
            show1: false,
            // password: '',
            // The params for password
            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 8 || 'Min 8 characters',
                emailMatch: () => (`The email and password you entered don't match`),
            },
        }
    },

    methods: {
        changeScreen(newValue) {
            this.$store.commit("setCurrentView", newValue);
        },
        setTime(newValue) {
            setTimeout(() => {
                this.alertMap.active = false
            }, 5000)
        },
        async verifyUser(zohoId) {
            this.alertMap.active = false;
            try{
                this.waitingLogin = true;
                let user = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/verify-account`,{
                    zohoId
                })
                this.waitingLogin = false;
                this.firstName = user.data.data.firstName
            }
            catch(error){
                this.waitingLogin = false;
                this.errorMessage = error.response.data.message;
                this.isError = true;
            }
        },
        async loginUser() {
            try {
                this.alertMap.active = false;
                if (this.zohoPin && this.zohoPin.length >= 4) {
                    this.waitingLogin = true;
                    let loginRes = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/verify-pin`, qs.stringify({
                        zohoPin: this.zohoPin,
                        zohoId: this.zohoId
                    }));
                    this.waitingLogin = false;
                    if (!!loginRes.data) {
                        let responseMessage = loginRes.data.message;
                        if (responseMessage.includes("Login success")) {
                            this.alertMap.active = false;
                            let tokenMaster = responseMessage.split("Login success ------ ")[1];
                            this.$store.commit("setUserDetails", loginRes.data.data);
                            this.changeScreen('loginPage');
                        } else {
                            this.alertMap.active = true;
                            this.alertMap.content = loginRes.data.message;
                        }
                    }

                }
                else if(this.zohoPin.length < 4 || this.zohoPin == null){
                    this.alertMap.content = "Pin must be atleast 4 digit";
                    this.alertMap.active = true;
                    this.setTime()
                }
            } catch (error) {
                this.waitingLogin = false;
                this.alertMap.active = true;
                this.alertMap.content = "Invalid pin";
            }
        }
    },

    async mounted() {
        var url = window.location.href;
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        let scToken = params.sct;
        if (!!scToken) {
            this.$store.commit("setCurrentView", "password");
            this.$store.commit("setSCToken", scToken);
            console.log(scToken);
        }
        
        // this.$store.commit("setCurrentView","login");
    }
}
</script>

<style>
#wallpaper {

    background-size: cover;
    background-image: url(https://images.squarespace-cdn.com/content/v1/6018b3b42273d3569232bc79/1616528555227-Q2VQEHVE9VA9B673YJAQ/blue%2Bbackground.001.jpg);
}

#wallpaper2 {
    background-image: url(https://images.squarespace-cdn.com/content/v1/6018b3b42273d3569232bc79/1616528555227-Q2VQEHVE9VA9B673YJAQ/blue%2Bbackground.001.jpg);
}

.v-application a {
    color: black !important;
}

a:link,
a:visited {
    text-decoration: none;
}

.form-control {
    margin-top: -30px;
}

.imp {
    min-width: 100%;
    background-color: white;
}
</style>
