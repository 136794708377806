import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueMq from '../node_modules/vue-mq'
import store from './store'

Vue.config.productionTip = false

Vue.use(VueMq, {
  breakpoints: {
    mobile: 450,
    tablet: 900,
    laptop: 1250,
    desktop: Infinity
  }
})

new Vue({
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
