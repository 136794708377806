<template>
  <v-simple-table fixed-header class="tableMain mx-auto">
    <thead class="tableHead">
      <tr>
        <th class="text-left">Name</th>
        <th class="text-left" >Action</th>
      </tr>
    </thead>
    <tbody class="tableBody">
      <tr v-for="(item, index) in items" :key="`${item.name}${index}`">
        <td class="content itemName text-truncate">
          <v-icon
            @click="$emit('fetchElement', item)"
            large
            :color="getIcon(item.mimeType).iconColor"
            style="border-right-width: 5px"
          >
            {{ getIcon(item.mimeType).iconType }}
          </v-icon>
          <span @click="$emit('fetchElement', item)" class="pl-2">
            {{ item.name }}
          </span>
        </td>
        <td class="text-center">
          <div class="d-flex btnWrap">
          <v-btn
            class="actionButton"
            @click="$emit('fetchElement', item)"
            elevation="0"
            tile
            small
            color="#9fc979"
            >View</v-btn
          >
          <v-btn
            v-if="getIcon(item.mimeType).iconType !== 'mdi mdi-folder'"
            class="actionButton"
            @click="$emit('downloadEl', item)"
            elevation="0"
            tile
            small
            color="#9fc979"
          >Download</v-btn>
          </div>
        </td>
      </tr>
    </tbody>
    <template #bottom v-if="page > 1 || hasNext">
      <v-row class="pt-3">
        <v-col v-if="page > 1">
          <v-btn
            class="actionButton"
            @click="$emit('prev')"
            elevation="0"
            tile
            small
            color="#9fc979"
            >Previous</v-btn
          >
        </v-col>
        <v-col class="d-flex" v-if="hasNext">
          <v-btn
            class="actionButton ml-auto mr-4"
            @click="$emit('next')"
            elevation="0"
            tile
            small
            color="#9fc979"
            >Next</v-btn
          >
        </v-col>
      </v-row>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: "FileCard",
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    page: {
      type: Number,
      default: 1
    },
    hasNext: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getIcon(type) {
      let iconType = "";
      let iconColor = "gray";
      if (type.includes("folder")) {
        iconType = "mdi mdi-folder";
        iconColor = "gray";
      } else if (type.includes("image")) {
        iconType = "mdi mdi-image";
        iconColor = "green";
      } else if (type.includes("document")) {
        iconType = "mdi mdi-file-document";
        iconColor = "blue";
      } else if (type.includes("pdf")) {
        iconType = "mdi mdi-file-pdf-box";
        iconColor = "red";
      } else if (type.includes("json")) {
        iconType = "mdi mdi-code-json";
      }
      return {
        iconType: iconType,
        iconColor: iconColor,
      };
    },
  },
};
</script>
<style >
.tableMain table {
  border-spacing: 0px 2px !important;
}
.tableMain .v-data-table__wrapper {
  max-height: 400px;
  padding-right: 10px;
}
</style>
<style scoped>
.tableMain {
  padding-top: 46px;
  padding-bottom: 26px;
  padding-left: 8px;
  padding-right: 18px;
  max-width: 870px;
  background: #ffffff;
  box-shadow: 0px 7px 5px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.tableHead tr th:last-child {
  box-shadow: 8px 0px 0px 0px #fff !important;
}
.tableHead tr th:first-child {
  box-shadow: none !important;
}
.tableHead tr th {
  font-family: "Inter" !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  color: #000000 !important;
}

.content {
  font-family: "Inter";
  font-weight: 400 !important;
  font-size: 20px !important;
  color: #000000;
}
.itemName {
  max-width: 490px;
  min-width: 300px;
  width: 100%;
}
.tableMain tr {
  height: 60px;
}
.tableMain tr td:first-child,
.tableMain tr th:first-child {
  padding-left: 55px;
}
.tableMain tr td:last-child,
.tableMain tr th:last-child {
  padding-right: 50px;
}
.tableMain tbody tr {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 7px 5px rgba(0, 0, 0, 0.25);
}
.tableMain tbody tr:hover {
  background: #ffffff !important;
}
.tableMain tbody tr td:first-child {
  border-width: 1px 0px 0px 1px;
}
.tableMain tbody tr td:last-child {
  border-width: 1px 1px 0px 0px;
}
.tableMain tbody tr td {
  border-style: solid;
  border-color: #d9d9d9;
}
.btnWrap {
  gap: 10px;
}
.actionButton {
  /* background-color: #9fc979 !important; */
  text-transform: capitalize;
  color: #ffffff;
}
.top-icon {
  color: rgba(0, 0, 0, 0.52);
  font-size: 30.77px;
}
</style>