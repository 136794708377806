<template>
    <v-app id="wallpaper3">
        <div class="d-flex align-center" style="padding-left: 20px; padding-top: 20px;">
            <!-- company logo -->
            <a href="https://www.inception.financial#howitworks">
                <v-img alt="Vuetify Logo" class="shrink mr-2" contain
                    src="//images.squarespace-cdn.com/content/v1/6018b3b42273d3569232bc79/1613011159112-72HUAC7QOUWOAR422W2N/Inception+Financial+Logo%404x.png?format=1500w"
                    transition="scale-transition" max-width="230px" />
            </a>

            <!-- bar buttons -->
            <v-col style="display: flex; justify-content: flex-end; padding-right: 50px;">
                <v-app-bar-nav-icon @click="overlay = !overlay" style="background-color: transparent;"></v-app-bar-nav-icon>

                <!-- button function -->
                <v-overlay :absolute="absolute" :opacity="opacity" :value="overlay" @wheel.prevent @touchmove.prevent
                    @scroll.prevent
                    style="display: flex; justify-content: center;  width:100%; max-height: 100vh; overflow: hidden;">

                    <div style="
                        display: flex; 
                        justify-content: flex-end; 
                        padding-bottom: 30px; 
                        margin-top: 0px; 
                        margin-left: -20px;">
                        <a href="https://www.inception.financial#howitworks" style="margin-top: -10px; margin-right: 50px;"
                            class="new2">
                            <v-img alt="Vuetify Logo" class="shrink mr-2" contain
                                src="//images.squarespace-cdn.com/content/v1/6018b3b42273d3569232bc79/1613011159112-72HUAC7QOUWOAR422W2N/Inception+Financial+Logo%404x.png?format=1500w"
                                transition="scale-transition" max-width="230px" />
                        </a>
                        <v-app-bar-nav-icon color="black lighten-2" @click="overlay = false"
                            style="font-size: 40px; color: white !important; background-color: transparent; margin-right: 20px;">
                            X
                        </v-app-bar-nav-icon>
                    </div>

                    <v-container class="new">
                        <h1><a href="https://www.inception.financial#benefits">BENEFITS</a></h1>
                        <h1><a href="https://www.inception.financial#howitworks">HOW IT WORKS</a></h1>
                        <h1><a href="https://www.inception.financial#FAQs">FAQs</a></h1>
                        <h1><a href="https://www.inception.financial#team">TEAM</a></h1>
                        <h1><a href="">INVESTORS</a></h1>

                        <v-btn style="
                            background-color: white; 
                            color: #97b464 !important;
                            margin-bottom: 100px; 
                            margin-left: 20%; 
                            font-size: 20px; 
                            height: 80px;
                            width: 200px; 
                            font-size: 25px;" href="https://www.inception.financial/general-2-1">
                            LEARN MORE
                        </v-btn>
                    </v-container>
                </v-overlay>
            </v-col>
        </div>

        <div>
            <v-col style="display: flex; justify-content: center; padding-top: 100px;">
                <p style="font-size: 20px;">New Password</p>
            </v-col>

            <v-form ref="forms">
                <v-col style="display: flex; justify-content: center;">
                    <!-- Text field password -->
                    <v-text-field v-model="password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min, rules.CapLet, rules.SpecialCar, rules.num]"
                        :type="show1 ? 'text' : 'password'" name="input-10-1" hide-details="true" solo class="imp" counter
                        @click:append="show1 = !show1" maxlength="16"></v-text-field>
                </v-col>

                <v-col style="display: flex; justify-content: center;  padding-top: 30px;">
                    <p style="font-size: 20px;">Confirm Password</p>
                </v-col>

                <v-col style="display: flex; justify-content: center;">
                    <!-- Text field password -->
                    <v-text-field v-model="password2" :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show2 ? 'text' : 'password'" name="input-10-1" solo class="imp" hide-details="true" counter
                        @click:append="show2 = !show2" maxlength="16"></v-text-field>
                </v-col>
            </v-form>

            <v-col id="lol" style="display: flex; justify-content: center;">
                <v-btn @click="need()"
                    :loading="waitingRes"
                    style="background-color: #97b464; color: white; padding: 27px; letter-spacing: .19em; font-size: 1.1rem; margin-top: 15%;">
                    CONFIRM
                </v-btn>
            </v-col>
        </div>

        <v-alert :value="alertMap.active" :color="alertMap.color" dark border="top" :icon="alertMap.icon" class="al"
            transition="scale-transition">
            {{ alertMap.content }}
        </v-alert>

</v-app>
</template>

<style>
#wallpaper3 {
    background-size: cover !important;
    background-image: url(https://images.squarespace-cdn.com/content/v1/6018b3b42273d3569232bc79/1616528555227-Q2VQEHVE9VA9B673YJAQ/blue%2Bbackground.001.jpg);
}

.v-btn--icon.v-size--default {
    height: 45px;
    width: 45px;
}

#wallpaper4 {
    background-size: cover;
    background-image: url(https://images.squarespace-cdn.com/content/v1/6018b3b42273d3569232bc79/1616528555227-Q2VQEHVE9VA9B673YJAQ/blue%2Bbackground.001.jpg);
}

.v-application a {
    color: white !important;
}

h1 {
    padding-bottom: 10%;
    padding-left: 10%;
    display: flex;
    font-size: 150%;
}

.v-overlay__scrim {
    border-radius: inherit;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: inherit;
    width: 100%;
    will-change: opacity;
    background-color: #97b464 !important;
}

.v-btn--icon.v-size--default .v-icon,
.v-btn--fab.v-size--default .v-icon {
    height: 24px;
    font-size: 50px;
    width: 24px;
    color: black;
}

/* you can modify but modify the movile for different parameters to desktop  */
@media (orientation: landscape) {

    .v-overlay__content {
        margin-right: 1%;
        margin-top: -5%;
    }

    .v-overlay__content .new2 {
        margin-right: 50% !important;
    }

    .v-overlay__content div {
        padding-bottom: 9;
    }

    .v-overlay__content h1 {
        padding-bottom: 10%;
        margin-left: 15%;
        font-size: 160%;
    }

    .v-overlay__content .new {
        column-count: 3;
        margin-top: -5%;
        margin-right: 10%;
        margin-left: -3%;
    }

    .v-overlay__content .v-btn:not(.v-btn--round).v-size--default {
        right: 10%;
        width: 100% !important;
        height: 50% !important;
        font-size: 140% !important;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: 25%;
    }
}

.imp {
    max-width: 600px;
    background-color: white;
}
</style>


<script>
import axios from "axios";
import qs from "qs";
export default {
    data: () => ({
        alertMap: {
            active: false,
            color: "",
            icon: "mdi-alert-outline",
            content: ""
        },
        email: 'testEmail',
        password: "",
        password2: "",
        show1: false,
        show2: false,
        waitingRes: false,
        // values necessary for password correct
        rules: {
            required: value => !!value || 'Required.',
            min: v => v.length >= 8 || 'Min 8 characters',
            CapLet: (value) => (value && /[A-Z]{1}/.test(value)) || 'At least one capital latter',
            SpecialCar: (value) => (value && /[^A-Za-z0-9]/.test(value)) || 'At least one special character',
            num: (value) => (value && /\d/.test(value)) || 'At least one number',
        },
        overlay: false,
        absolute: true,
        opacity: 1,
    }),

    updated() {
    },

    methods: {
        async need() {
            if (this.$refs.forms.validate()) {
                console.log('object');
                this.alertMap.active = false;
                // types of alerts for different cases, for example, when the password is the same, when it is done correctly, missing symbols or characters.
                if (!!this.password && !!this.password2) {
                    if (this.password == this.password2) {
                        try {
                            this.waitingRes = true;
                            console.log(this.$store.state.sctoken);
                            let updateRes = await axios.post('https://investorsportal-797836204.development.catalystserverless.com/server/investors_portal_function/updateEnd', qs.stringify({
                                password: this.password,
                                sc_token: this.$store.state.sctoken
                            }));
                            this.waitingRes = false;
                            if (!!updateRes.data) {
                                if (updateRes.data.message == "Password Updated Successfully") {
                                    this.setAlertProper("green", "mdi-checkbox-marked-circle", "Password Updated");
                                    this.waitingRes = true;
                                    let windowURL = window.location.href.split('?')[0];
                                    setTimeout(() => {
                                        window.location.replace(windowURL);
                                    }, 4000)
                                }
                                else if (!!updateRes.data.error) {
                                    if (updateRes.data.error.message == "jwt malformed") {
                                        this.setAlertProper("pink", "mdi-information", "Token not valid");
                                    }
                                }
                                else {
                                    this.setAlertProper("pink", "mdi-information", updateRes.data.message);
                                }
                            }
                            console.log(updateRes);
                        } catch (error) {
                            console.log(error.data);
                        }
                    }
                    else {
                        this.setAlertProper("pink", "mdi-information", "The password is the same as the old password or does not match the new password");
                    }
                }
                else {
                    this.setAlertProper("pink", "mdi-cancel", "Fill in all the boxes");
                }
            }
            else {
                this.setAlertProper("orange", "mdi-alert-outline", "It needs to have a symbol, capital letter or number.");
            }

        },
        setAlertProper(color, icon, content) {
            this.alertMap = {
                'active': true,
                'color': color,
                'icon': icon,
                'content': content,
            }
            setTimeout(() => {
                this.alertMap.active = false
            }, 15000)
        }
    },
}
</script>