<template>
<v-app id="wallpaper" style="background-color: #719dc7; 
    padding-top: 20px;">
    <div class="d-flex align-center" style="padding-left: 3%;">

        <!-- company logo -->
        <a href="https://www.inception.financial#howitworks">
            <v-img alt="Vuetify Logo" class="shrink mr-2" contain src="//images.squarespace-cdn.com/content/v1/6018b3b42273d3569232bc79/1613011159112-72HUAC7QOUWOAR422W2N/Inception+Financial+Logo%404x.png?format=1500w" transition="scale-transition" max-width="300px" />
        </a>

        <!-- bar buttons -->
        <v-col style="display: flex; justify-content: flex-end; padding-right: 70px;">
            <a class="d-flex align-center" href="https://www.inception.financial#benefits" style="margin-right: 20px; color: black !important;">
                BENEFITS
            </a>
            <a class="d-flex align-center" href="https://www.inception.financial#howitworks" style="margin-right: 20px; color: black !important;">
                HOW IT WORKS
            </a>
            <a class="d-flex align-center" href="https://www.inception.financial#FAQs" style="margin-right: 20px; color: black !important;">
                FAQs
            </a>
            <a class="d-flex align-center" href="https://www.inception.financial#team" style="margin-right: 20px; color: black !important;">
                TEAM
            </a>
            <a class="d-flex align-center" href="/investors" style="margin-right: 20px; color: black !important;">
                INVESTORS
            </a>
            <v-btn class="LEARMORE d-flex align-center" style="background-color:#97b464; color: white !important; padding: 23px; padding-left: 25px; padding-right: 25px;" href="https://www.inception.financial/general-2-1">
                LEARN MORE
            </v-btn>
        </v-col>
    </div>

    <!-- insert the data -->
    <div>
        <!-- <v-col style="display: flex; justify-content: center; padding-top: 100px;">
            <p style="margin-right: 300px !important; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: 45px;">Enter your email:</p>
        </v-col> -->

        <v-col class="p" style="display: flex; justify-content: center; margin-top: -1%; padding-bottom: 2%; color: white;padding-top: 100px;">
            <p>Please enter your portal email address to receive a password reset email:</p>
            <p style="color: red;">*</p>
        </v-col>

        <v-col style="display: flex; justify-content: center; margin-top: -30px;">
            <v-text-field v-on:keyup.enter="sendToken()" class="imp" v-model="email" type="text" solo hide-details="true"></v-text-field>
            <!-- <input style="background-color: white; border-style: none; padding: 10px; padding-right: 400px;" type="email" class="form-control" placeholder="Email" required> -->
        </v-col>

        <v-col style="display: flex; justify-content: center;">
            <v-btn @click="sendToken()" :loading="waitingRes" style="background-color: #97b464; color: white; padding: 27px; letter-spacing: .19em; font-size: 1.1rem; margin-top: 5%;">
                SEND
            </v-btn>
        </v-col>
    </div>
    <v-alert :value="alertMap.active" :color="alertMap.color" dark border="top" icon="mdi-cancel" class="al" transition="scale-transition">
        {{alertMap.content}}
    </v-alert>
</v-app>
</template>

<script>
import axios from "axios";
import qs from "qs";

export default {
    data() {
        return {
            email: "",
            waitingRes: false,
            alertMap: {
                active: false,
                color: "pink",
                content: "Fill in all the boxes"
            }
        }
    },
    async mounted() {

    },

    updated() {
        setTimeout(() => {
            this.alertMap.active = false
        }, 25000)
    },

    methods: {
        async sendToken() {
            try {
                if (!!this.email) {
                    this.waitingRes = true;
                    let recoverRes = await axios.post('https://investorsportal-797836204.development.catalystserverless.com/server/investors_portal_function/recoverEnd', qs.stringify({
                        email: this.email
                    }));
                    console.log(recoverRes);
                    this.waitingRes = false;
                    if (!!recoverRes.data) {
                        if (recoverRes.data.message == "Password reset email sent successfully. The email can take up to 5 minutes to arrive.") {
                            this.alertMap.color = "green";
                            this.email = "";
                            console.log(recoverRes.data.message);
                        } else {
                            this.alertMap.color = "pink";
                        }
                        this.alertMap.active = true;
                        this.alertMap.content = recoverRes.data.message;
                    }
                } else {
                    this.alertMap.color = "pink";
                    this.alertMap.active = true;
                    this.alertMap.content = "Email field is mandatory";
                }
            } catch (error) {
                console.log(error);
                this.waitingRes = false;
            }
        }

    }
}
</script>

<style>
#wallpaper {

    background-size: cover;
    background-image: url(https://images.squarespace-cdn.com/content/v1/6018b3b42273d3569232bc79/1616528555227-Q2VQEHVE9VA9B673YJAQ/blue%2Bbackground.001.jpg);
}

#wallpaper2 {
    background-image: url(https://images.squarespace-cdn.com/content/v1/6018b3b42273d3569232bc79/1616528555227-Q2VQEHVE9VA9B673YJAQ/blue%2Bbackground.001.jpg);
}

.v-application a {
    color: black !important;
}

a:link,
a:visited {
    text-decoration: none;
}

.form-control {
    margin-top: -30px;
}

.imp {
    max-width: 600px;
    background-color: white;
}
</style>
