<template>
  <v-card class="d-flex flex-column" height="100%">
    <v-card-title
      v-if="notDirectoryA"
      class="justify-center text-uppercase pa-5 white--text text-card-heading ff-inter fw-600"
      :style="{
        backgroundColor: index === 1 ? '#9EC6EC' : '#95BC71',
      }"
    >
      {{ item.name === "Reports" ? "Documents" : item.name }}
    </v-card-title>

    <v-card-text class="text-center py-10 px-13 ff-inter card-text">
      <template v-if="notDirectoryA">{{ para }}</template>
      <template v-else>{{ item.name }}</template>
    </v-card-text>

    <v-card-actions class="justify-center pt-0 px-6 pb-10 mt-auto">
      <v-btn
        @click="$emit('fetchElement')"
        large
        :min-width="175"
        max-width="100%"
        min-height="44px"
        height="auto"
        class="card-btn fw-600 text-capitalize white--text py-3"
        :color="notDirectoryA && index === 1 ? '#9EC6EC' : '#95BC71'"
      >
        {{ notDirectoryA ? (item.name === "Tranches" ? "Access Tranches" : item.name === "Reports" ? "Access Documents" : `Access ${item.name}`) : "Explore" }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
    props: {
        notDirectoryA: {
            default: false
        },
        index: {
            default: 0
        },
        para: {
            default: ""
        },
        item: {
            required: true,
            default: null
        },
    }
}
</script>
