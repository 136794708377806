<template>
  <v-app id="wallpaper" style="background-color: #719dc7">
    <div
      class="headingRow"
    >
      <!-- company logo -->
      <a href="https://www.inception.financial#howitworks">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="//images.squarespace-cdn.com/content/v1/6018b3b42273d3569232bc79/1613011159112-72HUAC7QOUWOAR422W2N/Inception+Financial+Logo%404x.png?format=1500w"
          transition="scale-transition"
          max-width="300px"
        />
      </a>

      <!-- bar buttons -->
      <v-col
        class="headingButton"
      >
        <!-- <a
          class="d-flex align-center"
          @click="returnHome"
          style="margin-right: 20px; color: black !important"
        >
          HOME
        </a> -->

        <v-btn
          @click="changeTab()"
          class="LEARMORE d-flex align-center"
          style="
            background-color: #97b464;
            color: white !important;
            padding: 23px;
          "
        >
          Customer Portal
        </v-btn>
      </v-col>
    </div>

    <v-dialog
      v-model="dialogloading"
      persistent
      color="transparent"
      content-class="elevation-0"
    >
      <v-card class="centerField" color="transparent">
        <v-card-text>
          <v-progress-circular
            :size="80"
            :width="7"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="previewFile">
      <v-card fluid style="height: 90vh">
        <div style="z-index: 9999; position: absolute; margin: 12px 0 0 12px">
          <v-btn
            class="preview-icons"
            @click="previewFile = false"
            small
            color="#343434"
            style="width: 20px; height: 40px; padding: 0; margin: 0 10px 0 0"
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
          <v-btn
            @click="downloadEl(currentItem)"
            class="preview-icons"
            small
            color="#343434"
            style="width: 20px; height: 40px"
          >
            <v-icon color="white">mdi-download</v-icon>
          </v-btn>
        </div>
        <iframe
          :src="previewURL"
          width="100%"
          height="99%"
          class="preview-container"
        >
        </iframe>
      <!-- <img :src="previewURL" width="100%" height="99%" /> -->
      </v-card>
    </v-dialog>

    <v-container fluid class="mb-1">
      <!-- <v-card elevation=0 style="padding-bottom: 1em;"> -->
      <!-- <div style="position: absolute; right: 0; width: 50%; padding: 3px 0 0 0; margin: 0; display: flex; justify-content: end; ">
                <v-img alt="Vuetify Logo" class="shrink mr-2" contain src="./../assets/inceptionLogo.png" transition="scale-transition" max-width="60px" />
            </div> -->

      <!-- <v-card-title> {{ masterFolderName }} </v-card-title> -->
      <v-card-text
        v-if="directoryA.length > 0"
        class="c-pointer back-button"
        style="display: flex; align-items:center;"
        @click="returnFolder()"
      >
        <v-icon v-if="directoryA.length > 0" color="white">
          mdi mdi-arrow-left
        </v-icon>
        <!-- <span v-if="directoryA.length > 0">
                    Back to '{{ pastFName }}'
                </span> -->

        <span v-if="directoryA.length > 0" class="forwhite-text">
          Go Back
        </span>
      </v-card-text>
      <div
        v-if="directoryA.length == 0"
        class="heading-container mb-16 pb-7 mt-10"
      >
        <h1
          class="headingone text-center font-weight-regular white--text pa-0 mb-5"
        >
          {{ headingText }}
        </h1>
        <p
          class="headingOnePara text-center font-weight-regular white--text mb-0"
        >
          {{ headingTextTwo }}
        </p>
      </div>
      <div
        v-else-if="directoryA.length > 0"
        class="heading-container mb-16 mt-10"
      >
        <h1
          class="headingone text-center font-weight-regular white--text pa-0 mb-2"
        >
          {{
            currentFolderName === "Reports" || currentFolderName === "Documents"
              ? "Presentations, Legal Agreements, and Performance Reports."
              : currentFolderName === "Tranches" ? "Individual Asset folders organized by Tranche." : currentFolderName
          }}
        </h1>
      </div>

      <v-container
        v-if="parentFolder.some((item) => item.fileExtension === 'pdf')"
        class="bg-surface-variant"
      >
        <v-row justify="center">
          <v-col cols="12" lg="8" xl="6">
            <FileCard
              :items="parentFolder"
              @fetchElement="fetchElement"
              @downloadEl="downloadEl"
              :page="page"
              :hasNext="hasNext"
              @next="handleNext"
              @prev="handlePrev"
            />
          </v-col>
          
          <v-col v-if="mainFolder !== 'Documents' && mainFolder !== 'Reports' && (!currentFolderName || currentFolderName != 'Reports' || currentFolderName != 'Documents')" cols="12" lg="4" xl="3">
            <DocumentCard />
          </v-col>
        </v-row>
      </v-container>
      <v-container
        v-else-if="
          parentFolder.some(
            (item) => item.mimeType === 'application/vnd.google-apps.folder'
          ) && directoryA?.length > 0
        "
        class="bg-surface-variant"
      >
        <v-row justify="center">
          <v-col cols="12" lg="9" xl="6">
            <VerticalTranch
              :items="parentFolder"
              @fetchElement="fetchElement"
              :page="page"
              :hasNext="hasNext"
              @next="handleNext"
              @prev="handlePrev"
            />
          </v-col>
        </v-row>
      </v-container>
      
      <v-container v-else class="bg-surface-variant">
        <v-row :justify="parentFolder?.length > 2 ? 'start' : 'center'">
          <v-col
            v-for="(item, index) in parentFolder"
            :key="item.i"
            cols="12"
            md="6"
            lg="4"
          >
            <TranchCard
              :notDirectoryA="directoryA?.length == 0"
              :item="item"
              :index="index"
              :para="index === 0 ? para1 : para2"
              @fetchElement="handleFetchElement(item, item.name)"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <v-alert v-if="this.alertMap.active" :value="alertMap.active" :color="alertMap.color" style="" dark border="top" icon="mdi-cancel" style="width: 50%; margin:auto;" class="al" transition="scale-transition">
        {{alertMap.content}}
    </v-alert>
    <!-- Footer -->
    <v-container fluid class="mt-auto pt-16">
      <v-row no-gutters>
        <v-col cols="12" sm="6">
          <p class="white--text">© 2020-2024 Inception Financial</p>
        </v-col>
        <v-col cols="12" sm="6">
          <p class="d-flex flex-column text-md-right white--text">
            <span>
              Have a question? Please reach out to us at:
              info@inception.financial
            </span>
            <span> or call your designated contact. </span>
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import axios from "axios";
import qs from "qs";
import TranchCard from "./TranchCard";
import VerticalTranch from "./VerticalTranch.vue";
import FileCard from "./FileCard.vue";
import DocumentCard from "./DocumentCard.vue";

export default {
  components: {
    TranchCard,
    VerticalTranch,
    FileCard,
    DocumentCard,
  },
  data: () => ({
    pastFName: "",
    mainFolder: "",
    currentItem: {},
    dialogloading: false,
    previewFile: false,
    previewsecondstep: false,
    previewURL: "",
    masterFolderID: "1XK9vkMHRPWmADqMqjuZoE-OnWexMG6n9",
    masterFolderName: "My Folder",
    directoryA: [],
    currentFolderID: "",
    currentFolderName: "",
    alignments: ["start", "center", "end"],
    parentFolder: [],
    headingText: "Welcome to your Inception Financial client portal.",
    headingTextTwo:
      "Here you can view and download all the records associated with your completed transactions.",
    para1: "Individual Asset folders organized by Tranche.",
    para2:
      "Presentations, Legal Agreements, and Performance Reports.",
    page: 1,
    hasNext: false,
    pageTokens: {},
    alertMap: {
      active: false,
      color: "pink",
      content: ""
    },
  }),
  async mounted() {
    try {
      this.dialogloading = true;
      await this.fetchFolderId()
      await this.fetchFolder(
        this.$store.state.setDriveFolderID,
        this.masterFolderName,
        true
      );
      this.dialogloading = false;
    } catch (error) {
      console.error(error);
    }
  },

  methods: {
    async handleNext() {
      this.page += 1;
      await this.fetchFolder(this.currentFolderID, this.currentFolderName, false, this.pageTokens[this.page]);
    },
    async handlePrev() {
      if (this.page > 1) {
        this.page -= 1;
      }
      await this.fetchFolder(this.currentFolderID, this.currentFolderName, false, this.pageTokens[this.page]);
    },
    handleFetchElement(item, folder) {
      this.mainFolder = folder;
      this.fetchElement(item)
    },
    changeScreen(newValue) {
      this.$store.commit("setCurrentView", "login");
    },
    async downloadEl(item) {
      try {
        this.dialogloading = true;
        const token = (this.$store.state.setUserDetails).token
        const response = await axios.post(
        `${process.env.VUE_APP_BACKEND_URL}/download-file`,
        item,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob"
        }
      );
      this.dialogloading = false;
      if (!response) {
        console.log("No response")
        return;
      }

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', item.name);
      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
      } catch (error) {
        console.error(error);
      }
    },
    sortArray(a, b) {
      if (a.mimeType.includes("folder")) {
        return -1;
      } else {
        return 1;
      }
    },
    changeTab(){
      const url = new URLSearchParams(window.location.search)
      const id = url.get("id");
      const newUrl = `https://api.inceptionfinancial.app/dashboard?id=`
      window.open(`${newUrl}${id}`, '_blank')
    },
    async fetchElement(item) {
      if (item.mimeType.includes("folder")) {
        await this.fetchFolder(item.id, item.name, false);
      } else {
        let fileData = await this.fetchFile(item.id);
        this.currentItem = item;
      }
    },
    async fetchFile(id) {
      try {
        const token = (this.$store.state.setUserDetails).token
        this.previewURL = "";
        this.dialogloading = true;
        let getFileURL = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/fetch-file?id=${id}`,{
          headers:{
            Authorization : `Bearer ${token}`
          }
        });
        this.dialogloading = false;
        if (!!getFileURL.data.data) {
          let fileURl = getFileURL.data.data.link;
          const lastIndex = fileURl.lastIndexOf("view");
          const replacement = "sdsd";
          this.previewURL = fileURl.replace("view?usp=drivesdk", "preview");
          this.previewFile = true;
        }
      } catch (error) {
        this.alertMap.content = "Something went wrong.";
        this.alertMap.active = true;
        return error;
      }
    },
    async fetchFolder(id, name, back, pageToken) {

      try{
        const token = (this.$store.state.setUserDetails).token
        if(!id){
          this.alertMap.content = "No documents found";
          this.alertMap.active = true;
          return;
        }
        this.dialogloading = true;
        const data = {
          id: id,
          nextPageToken: pageToken || ""
        }
        
        let getFolders = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/fetch-documents`,data,{
          headers:{
            Authorization : `Bearer ${token}`
          },
        })
        this.dialogloading = false;
        this.previewsecondstep = true;
        if (id !== this.currentFolderID || back) {
          this.page = 1;
          this.pageTokens = {}
        }
  
        if (!!getFolders.data.data) {
          let gdriveA = getFolders.data.data.message.files;
          this.hasNext = !!getFolders.data.data.message.nextPageToken;
          if (getFolders.data.data.message.nextPageToken) {
            this.pageTokens[this.page + 1] = getFolders.data.data.message.nextPageToken
          }
          if (!!gdriveA) {
            gdriveA.sort(this.sortArray);
            if (!back && id !== this.currentFolderID) {
              this.directoryA.push({
                id: this.currentFolderID,
                name: this.currentFolderName,
              });
              this.pastFName = this.currentFolderName;
            }
            this.currentFolderID = id;
            this.currentFolderName = name;
            this.parentFolder = gdriveA;
          }
          return getFolders.data.message;
        }
        return "Folder doesn't exist";
      }
      catch(error){
        this.alertMap.content = "Something went wrong.";
        this.alertMap.active = true;
        return error
      }
    },
    async fetchFolderId() {

      try{
        const token = (this.$store.state.setUserDetails).token
        this.dialogloading = true;
        
        let getFolderId = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/fetch-folderId`,{
          headers:{
            Authorization : `Bearer ${token}`
          }
        })
        this.dialogloading = false;
        if(getFolderId.data){
          this.$store.commit("setDriveFolderID", getFolderId.data.data.GDriveID);
          this.dialogloading = false;
          return getFolders.data.message;
        }
        return "Folder doesn't exist";
      }
      catch(error){
        return error;
      }
    },
    async returnFolder() {
      let lastEl = this.directoryA[this.directoryA.length - 1];
      this.dialogloading = true;
      await this.fetchFolder(lastEl.id, lastEl.name, true);
      this.directoryA.pop();
      this.pastFName = this.directoryA[this.directoryA.length - 1]?.name;
      this.dialogloading = false;
    },
    async returnHome() {
      if (!this.directoryA.length) return;
      let lastEl = this.directoryA[0];
      this.dialogloading = true;
      await this.fetchFolder(lastEl.id, lastEl.name, true);
      this.directoryA = [];
      this.pastFName = lastEl?.name;
      this.dialogloading = false;
    },
  },
};
</script>

<style>
.centerField {
  display: flex !important;
  justify-content: center !important;
  text-align: center !important;
}

.v-overlay__scrim {
  background-color: #5dade2 !important;
}

.btn-flotante {
  font-size: 16px;
  /* Cambiar el tamaño de la tipografia */
  text-transform: uppercase;
  /* Texto en mayusculas */
  font-weight: bold;
  /* Fuente en negrita o bold */
  color: #ffffff !important;
  /* Color del texto */
  border-radius: 5px;
  /* Borde del boton */
  letter-spacing: 2px;
  /* Espacio entre letras */
  background-color: #e91e63 !important;
  /* Color de fondo */
  padding: 18px 30px;
  /* Relleno del boton */
  position: fixed;
  bottom: 40px;
  right: 40px;
  transition: all 300ms ease 0ms;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  z-index: 99;
}

.btn-flotante:hover {
  background-color: #2c2fa5;
  /* Color de fondo al pasar el cursor */
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
  transform: translateY(-7px);
}

@media only screen and (max-width: 600px) {
  .btn-flotante {
    font-size: 14px;
    padding: 12px 20px;
    bottom: 20px;
    right: 20px;
  }
}

.heading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.headingone,
.headingonePara {
  font-family: "Roboto";
  letter-spacing: 0.02em;
}
.headingone {
  font-size: 30px;
  line-height: 35px;
}
.headingonePara {
  font-size: 23px;
  line-height: 27px;
}

.headingtwo {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 40%;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.container {
  padding: 2px 16px;
}

.header-class-div {
  padding: 6px;
  height: 38px;
}

.forwhite-text {
  color: white !important;
  font-size: 16px;
}

.card-body-cst {
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ff-inter {
  font-family: "Inter", sans-serif;
}
.fw-600 {
  font-weight: 600;
}
.text-card-heading {
  font-size: 20px;
  line-height: 24px;
}
.card-btn {
  font-size: 17px;
  line-height: 22px;
}
.card-text {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}
.c-pointer {
  cursor: pointer;
}
.back-button {
  width: fit-content !important;
  max-width: 140px;
  border:1px solid white;
  border-radius: 1rem;
  margin-left: 55px
}
.headingButton{
  display: flex;
  justify-content: flex-end;
  padding-right: 70px 
}
.headingRow{
  display: flex;
  align-items: center;
  padding-left: 3%;
  padding-top: 20px
}
@media screen and (max-width: 600px) {
  .headingButton{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px 
  }
  .headingRow{;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 0%;
    padding-top: 0px
  }
  .back-button {
    width: fit-content !important;
    max-width: 140px;
    margin-left: 0px;
  }
}

</style>
