import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentView: "login",
    sctoken: "",
    setDriveFolderID: "",
    setUserDetails: {},
  },
  getters: {
    getCurrentView(state) {
      return state.currentView;
    },
  },
  mutations: {
    setCurrentView(state, payload) {
      state.currentView = payload;
    },
    setSCToken(state, payload) {
      state.sctoken = payload;
    },
    setDriveFolderID(state, payload) {
      state.setDriveFolderID = payload;
    },
    setUserDetails(state, payload) {
      state.setUserDetails = payload;
    },
  },
  actions: {},
  modules: {},
});
