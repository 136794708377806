<template>
  <v-app>
    <v-main>
      <!-- separate mobile version and desktop version  -->
      <EmailD v-if = "($mq == 'laptop' || $mq == 'desktop') && (this.$store.state.currentView == 'Email1')"></EmailD>
      <EmailP v-if = "($mq == 'mobile' || $mq == 'tablet') && (this.$store.state.currentView == 'Email1')"></EmailP>
      <Passw v-if = "($mq == 'laptop' || $mq == 'desktop') && (this.$store.state.currentView == 'password')"></Passw>
      <PasswP v-if = "($mq == 'mobile' || $mq == 'tablet') && (this.$store.state.currentView == 'password')"></PasswP>
      <ViewVueD v-if = "($mq == 'laptop' || $mq == 'desktop') && (this.$store.state.currentView == 'login')"></ViewVueD>
      <ViewVueP v-if = "($mq == 'mobile' || $mq == 'tablet') && (this.$store.state.currentView == 'login')"></ViewVueP>
      <LoginPage v-if = "($mq == 'laptop' || $mq == 'desktop' || $mq == 'mobile' || $mq == 'tablet') && (this.$store.state.currentView == 'loginPage')"></LoginPage>
      <!-- <LoginPage/> -->
    </v-main>
  </v-app>
</template>

<script>
import ViewVueD from './components/ViewVueD';
import ViewVueP from './components/ViewVueP';
import Passw from './components/Passw';
import PasswP from './components/PasswP';
import EmailD from './components/EmailD';
import EmailP from './components/EmailP';
import LoginPage from './components/loginPage';

export default {
  name: 'App',

  components: {
    ViewVueD,
    ViewVueP,
    Passw,
    PasswP,
    EmailD,
    EmailP,
    LoginPage
  },

  data: () => ({
    //
  }),
};
</script>

<style>
body,
html {
  overflow-y: auto !important;
}
/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background: #79747E;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #79747E;
}
</style>
