<template>
  <v-simple-table fixed-header class="tableMain mx-auto">
    <thead class="tableHead">
      <tr>
        <th class="text-left">Name</th>
        <th class="text-left">Action</th>
      </tr>
    </thead>
    <tbody class="tableBody">
      <tr v-for="(item, index) in items" :key="`${item.name}${index}`">
        <td class="content itemName text-truncate">{{ item.name }}</td>
        <td class="text-left btnWrap">
          <v-btn
            class="actionButton"
            @click="$emit('fetchElement', item)"
            elevation="0"
            tile
            >View</v-btn
          >
        </td>
      </tr>
    </tbody>
    <template #bottom v-if="page > 1 || hasNext">
      <v-row class="pt-3">
        <v-col v-if="page > 1">
          <v-btn
            class="paginationButton"
            @click="$emit('prev')"
            elevation="0"
            tile
            small
            >Previous</v-btn
          >
        </v-col>
        <v-col class="d-flex" v-if="hasNext">
          <v-btn
            class="paginationButton ml-auto mr-4"
            @click="$emit('next')"
            elevation="0"
            tile
            small
            >Next</v-btn
          >
        </v-col>
      </v-row>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: "VerticalTranch",
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    page: {
      type: Number,
      default: 1
    },
    hasNext: {
      type: Boolean,
      default: false
    }
  },
};
</script>
<style >
.tableMain table {
  border-spacing: 0px 2px !important;
}
.tableMain .v-data-table__wrapper {
  max-height: 400;
  padding-right: 8px;
}
</style>
<style scoped>
.tableMain {
  padding-top: 46px;
  padding-bottom: 26px;
  padding-left: 8px;
  padding-right: 18px;
  max-width: 870px;
  background: #ffffff;
  box-shadow: 0px 7px 5px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.tableHead tr th {
  font-family: "Inter" !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  color: #000000 !important;
  box-shadow: none !important;
}
.tableHead tr th:last-child {
  box-shadow: 8px 0px 0px 0px #fff !important;
}
.tableHead tr th:first-child {
  box-shadow: none !important;
}
.content {
  font-family: "Inter";
  font-weight: 400 !important;
  font-size: 20px !important;
  color: #000000;
}
.itemName {
  max-width: 600px;
  width: 100%;
}
.tableMain tr {
  height: 60px;
}
.tableMain tr td:first-child,
.tableMain tr th:first-child {
  padding-left: 55px;
}
.tableMain tr td:last-child,
.tableMain tr th:last-child {
  padding-right: 55px;
}
.tableMain tbody tr {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 7px 5px rgba(0, 0, 0, 0.25);
}
.tableMain tbody tr:hover {
  background: #ffffff !important;
}
.tableMain tbody tr td:first-child {
  border-width: 1px 0px 0px 1px;
}
.tableMain tbody tr td:last-child {
  border-width: 1px 1px 0px 0px;
}
.tableMain tbody tr td {
  border-style: solid;
  border-color: #d9d9d9;
}
.actionButton {
  background-color: #9fc979 !important;
  text-transform: capitalize;
  width: 96.29px;
  height: 32.35px;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  text-align: center;
  color: #ffffff;
}
.paginationButton {
  background-color: #9fc979 !important;
  text-transform: capitalize;
  color: #ffffff;
}
</style>