<template>
<v-app id="wallpaper3">
    <div class="d-flex align-center" style="padding-left: 20px; padding-top: 20px;">
        <!-- company logo -->
        <a href="https://www.inception.financial#howitworks">
            <v-img alt="Vuetify Logo" class="shrink mr-2" contain src="//images.squarespace-cdn.com/content/v1/6018b3b42273d3569232bc79/1613011159112-72HUAC7QOUWOAR422W2N/Inception+Financial+Logo%404x.png?format=1500w" transition="scale-transition" max-width="230px" />
        </a>

        <!-- bar buttons -->
        <v-col style="display: flex; justify-content: flex-end; padding-right: 50px;">
            <!-- <v-app-bar-nav-icon style="background-color: #97b464;"></v-app-bar-nav-icon> -->
            <v-app-bar-nav-icon @click="overlay = !overlay" style="background-color: transparent;"></v-app-bar-nav-icon>

            <!-- button function -->

            <v-overlay :absolute="absolute" :opacity="opacity" :value="overlay" @wheel.prevent @touchmove.prevent @scroll.prevent style="display: flex; justify-content: center;  width:100%; max-height: 100vh; overflow: hidden;">

                <div style="
                    display: flex; 
                    justify-content: flex-end; 
                    padding-bottom: 30px; 
                    margin-top: 0px; 
                    margin-left: -20px;">
                    <a href="https://www.inception.financial#howitworks" style="margin-top: -10px; margin-right: 50px;" class="new2">
                        <v-img alt="Vuetify Logo" class="shrink mr-2" contain src="//images.squarespace-cdn.com/content/v1/6018b3b42273d3569232bc79/1613011159112-72HUAC7QOUWOAR422W2N/Inception+Financial+Logo%404x.png?format=1500w" transition="scale-transition" max-width="230px" />
                    </a>
                    <v-app-bar-nav-icon color="black lighten-2" @click="overlay = false" style="font-size: 40px; color: white !important; background-color: transparent; margin-right: 20px;">
                        X
                    </v-app-bar-nav-icon>
                </div>

                <v-container class="new">
                    <h1><a href="https://www.inception.financial#benefits">BENEFITS</a></h1>
                    <h1><a href="https://www.inception.financial#howitworks">HOW IT WORKS</a></h1>
                    <h1><a href="https://www.inception.financial#FAQs">FAQs</a></h1>
                    <h1><a href="https://www.inception.financial#team">TEAM</a></h1>
                    <h1><a href="">INVESTORS</a></h1>

                    <v-btn style="
                        background-color: white; 
                        color: #97b464 !important;
                        margin-bottom: 100px; 
                        margin-left: 20%; 
                        font-size: 20px; 
                        height: 80px;
                        width: 200px; 
                        font-size: 25px;" href="https://www.inception.financial/general-2-1">
                        LEARN MORE
                    </v-btn>
                </v-container>
            </v-overlay>
        </v-col>
    </div>

    <div>
        <!-- <v-col style="display: flex; justify-content: center; padding-top: 100px;">
            <p style="font-size: 40px;">Enter your email:</p>
        </v-col> -->

        <v-col style="display: flex; justify-content: center;  margin-top: -2%; padding-bottom: 40px;padding-top: 100px;">
            <p style="font-size: 15px;">Enter your Email and check the verification message*</p>
        </v-col>

        <v-col style="display: flex; justify-content: center; margin-top: -50px;">
            <v-text-field class="imp" v-model="email" type="text" solo hide-details="true"></v-text-field>
            <!-- <input style="background-color: white; border-style: none; padding: 10px; padding-right: 400px; padding-right: 35%;" type="email" class="form-control" placeholder="Email" required> -->
        </v-col>

        <v-col id="lol" style="display: flex; justify-content: center;">
            <v-btn @click="sendToken()" :loading="waitingRes" style="background-color: #97b464; color: white; padding: 27px; letter-spacing: .19em; font-size: 1.1rem; margin-top: 20px;">
                SEND
            </v-btn>
        </v-col>
    </div>

    <!-- A map has been created for the use of different descriptions of the alerts -->
    <v-alert :value="alertMap.active" :color="alertMap.color" dark border="top" icon="mdi-cancel" class="al" transition="scale-transition">
        {{alertMap.content}}
    </v-alert>
</v-app>
</template>

<style>
#wallpaper3 {
    background-size: cover !important;
    background-image: url(https://images.squarespace-cdn.com/content/v1/6018b3b42273d3569232bc79/1616528555227-Q2VQEHVE9VA9B673YJAQ/blue%2Bbackground.001.jpg);
}

.v-btn--icon.v-size--default {
    height: 45px;
    width: 45px;
}

#wallpaper4 {
    background-size: cover;
    background-image: url(https://images.squarespace-cdn.com/content/v1/6018b3b42273d3569232bc79/1616528555227-Q2VQEHVE9VA9B673YJAQ/blue%2Bbackground.001.jpg);
}

.v-application a {
    color: white !important;
}

h1 {
    padding-bottom: 10%;
    padding-left: 10%;
    display: flex;
    font-size: 150%;
}

.v-overlay__scrim {
    border-radius: inherit;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: inherit;
    width: 100%;
    will-change: opacity;
    background-color: #97b464 !important;
}

.v-btn--icon.v-size--default .v-icon,
.v-btn--fab.v-size--default .v-icon {
    /* : 24px; */
    font-size: 50px;
    width: 24px;
    color: black;
}

/* you can modify but modify the movile for different parameters to desktop  */
@media (orientation: landscape) {

    .v-overlay__content {
        margin-right: 1%;
        margin-top: -5%;
    }

    .v-overlay__content .new2 {
        margin-right: 50% !important;
    }

    .v-overlay__content div {
        padding-bottom: 9;
    }

    .v-overlay__content h1 {
        padding-bottom: 10%;
        margin-left: 15%;
        font-size: 160%;
    }

    .v-overlay__content .new {
        column-count: 3;
        margin-top: -5%;
        margin-right: 10%;
        margin-left: -3%;
    }

    .v-overlay__content .v-btn:not(.v-btn--round).v-size--default {
        right: 10%;
        width: 100% !important;
        height: 50% !important;
        font-size: 140% !important;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: 25%;
    }
}
</style>

<script>
import axios from "axios";
import qs from "qs";
export default {
    data: () => ({
        email: "",
        waitingRes: false,
        alertMap: {
            active: false,
            color: "pink",
            content: "Fill in all the boxes"
        },
        absolute: true,
        opacity: 1,
        overlay: false,
    }),

    // In this part you asignal the time the alert
    updated() {
        setTimeout(() => {
            this.alertMap.active = false
        }, 15000)
    },

    methods: {
        async sendToken() {
            try {
                if (!!this.email) {
                    this.waitingRes = true;
                    let recoverRes = await axios.post('https://investorsportal-797836204.development.catalystserverless.com/server/investors_portal_function/recoverEnd', qs.stringify({
                        email: this.email
                    }));
                    this.waitingRes = false;
                    if (!!recoverRes.data) {
                        if (recoverRes.data.message == "Password reset email sent successfully. The email can take up to 5 minutes to arrive.") {
                            this.alertMap.color = "green";
                            this.email = "";
                            console.log(recoverRes.data.message);
                        } else {
                            this.alertMap.color = "pink";
                        }
                        this.alertMap.active = true;
                        this.alertMap.content = recoverRes.data.message;
                    }
                } else {
                    this.alertMap.color = "pink";
                    this.alertMap.active = true;
                    this.alertMap.content = "Email field is mandatory";
                }
            } catch (error) {
                console.log(error);
                this.waitingRes = false;
            }

        }

    }
}
</script>
