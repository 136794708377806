<template>
<v-app id="wallpaper3">
    <div class="d-flex align-center" style="padding-left: 20px; padding-top: 20px;">
        <!-- Company logo -->
        <a href="https://www.inception.financial#howitworks">
            <v-img alt="Vuetify Logo" class="shrink mr-2" contain src="//images.squarespace-cdn.com/content/v1/6018b3b42273d3569232bc79/1613011159112-72HUAC7QOUWOAR422W2N/Inception+Financial+Logo%404x.png?format=1500w" transition="scale-transition" max-width="230px" />
        </a>

        <!-- Bar buttons -->
        <!-- <v-col style="display: flex; justify-content: flex-end; padding-right: 50px;">
            <v-app-bar-nav-icon @click="overlay = !overlay" style="background-color: transparent;"></v-app-bar-nav-icon>

            <v-overlay :absolute="absolute" :opacity="opacity" :value="overlay" @wheel.prevent @touchmove.prevent @scroll.prevent style="display: flex; justify-content: center;  width:100%; max-height: 100vh; overflow: hidden;">

                <div style="
                    display: flex; 
                    justify-content: flex-end; 
                    padding-bottom: 30px; 
                    margin-top: 0px; 
                    margin-left: -20px;">
                    <a href="https://www.inception.financial#howitworks" style="margin-top: -10px; margin-right: 50px;" class="new2">
                        <v-img alt="Vuetify Logo" class="shrink mr-2" contain src="//images.squarespace-cdn.com/content/v1/6018b3b42273d3569232bc79/1613011159112-72HUAC7QOUWOAR422W2N/Inception+Financial+Logo%404x.png?format=1500w" transition="scale-transition" max-width="230px" />
                    </a>
                    <v-app-bar-nav-icon color="black lighten-2" @click="overlay = false" style="font-size: 40px; color: white !important; background-color: transparent; margin-right: 20px;">
                        X
                    </v-app-bar-nav-icon>
                </div>

                <v-container class="new">
                    <h1><a href="https://www.inception.financial#benefits">BENEFITS</a></h1>
                    <h1><a href="https://www.inception.financial#howitworks">HOW IT WORKS</a></h1>
                    <h1><a href="https://www.inception.financial#FAQs">FAQs</a></h1>
                    <h1><a href="https://www.inception.financial#team">TEAM</a></h1>
                    <h1><a href="">INVESTORS</a></h1>

                    <v-btn style="
                        background-color: white; 
                        color: #97b464 !important;
                        margin-bottom: 100px; 
                        margin-left: 20%; 
                        font-size: 20px; 
                        height: 80px;
                        width: 200px; 
                        font-size: 25px;" href="https://www.inception.financial/general-2-1">
                        LEARN MORE
                    </v-btn>
                </v-container>
            </v-overlay>
        </v-col> -->
    </div>

    <div style="height:70vh; color: white; display: flex; flex-direction: column; align-items:center;">
        <div v-if="!waitingLogin && isError !== true" style=" width:70%">
            <v-col style="display: flex; flex-direction: column; justify-content: center;font-size: 1.8rem; padding-top: 100px;padding-bottom: 0px;">
                <p style="">Security Pin</p>
                <p style="font-size: 1.3rem; margin: 0; display: flex;">Hey&nbsp;<span style="color: #C5FF95; display: flex;">{{firstName || "User"}}</span>, Enter Your Pin</p>
            </v-col>

            <v-col style="display: flex; justify-content: center;margin: 0; padding-top:0;">
                <!-- Text field email -->
                <v-text-field class="imp" v-model="zohoPin" type="text" solo hide-details="true"></v-text-field>
            </v-col>
            <v-col id="lol" style="display: flex; justify-content: center;">
                <v-btn @click="loginUser()" :loading="waitingLogin" style="background-color: #97b464; color: white; padding: 27px; letter-spacing: .19em; font-size: 1.1rem; margin-top: 20px;border-radius:1.5rem;">
                    LOGIN
                </v-btn>
            </v-col>
        </div>
        <div v-else-if="isError === true" style="display: flex; justify-content: center; align-items: center; height: 100%">
            <v-text style="color: red; font-size: 2.5rem;">{{errorMessage}}</v-text>
        </div>
        <div v-else style="display: flex; justify-content: center; align-items: center; height: 100%">
            <v-progress-circular indeterminate color="#97b464" style="width:50px; height: 50px;" ></v-progress-circular>
        </div>
    </div>

    <!-- A map has been created for the use of different descriptions of the alerts -->
    <v-alert :value="alertMap.active" :color="alertMap.color" dark border="top" icon="mdi-cancel" style="width: 50%; margin:auto" class="al" transition="scale-transition">
        {{alertMap.content}}
    </v-alert>

    <!-- footer -->
    <v-footer dark padless>
        <v-card id="wallpaper4" flat tile class="lighten-1 white--text">
            <v-row class="mb-12 ms-0 me-0">
                <v-col>
                    <v-card-text class="white--text" style="font-size: 5vw; display: flex; justify-content: center; padding-top: 10%;">
                        <p>
                            © 2020-2024 Inception Financial
                        </p>
                    </v-card-text>

                    <v-card-text class="with--text pt-0" style="font-size: 3vw;">
                        <p style="margin-right: 5%; margin-left: 5%;">
                            DISCLAIMER
                            <br>
                            <br>
                            The information provided represents the opinion of Inception Financial and is not intended to be a forecast of future events or guarantee of future results. It is not intended to provide specific investment advice and should not be construed as an offering of securities or recommendation to invest nor should it be used as a primary basis of investment decisions. It is not to be construed to meet the needs of any particular investor, nor is it a representation or solicitation or an offer to sell/buy any security. Investors should consult with their investment professional for advice concerning their particular situation
                            <br>
                            <br>
                            Discussion of U.S. Federal Tax Implication of such investments is meant to be general in nature and does not discuss all aspects of U.S. Federal Income Taxation that may be relevant to a prospective Investor.</p>
                    </v-card-text>
                </v-col>
            </v-row>
        </v-card>
    </v-footer>
</v-app>
</template>

<style>
#wallpaper3 {
    background-size: cover !important;
    background-image: url(https://images.squarespace-cdn.com/content/v1/6018b3b42273d3569232bc79/1616528555227-Q2VQEHVE9VA9B673YJAQ/blue%2Bbackground.001.jpg);
}

.v-btn--icon.v-size--default {
    height: 45px;
    width: 45px;
}

#wallpaper4 {
    background-size: cover;
    background-image: url(https://images.squarespace-cdn.com/content/v1/6018b3b42273d3569232bc79/1616528555227-Q2VQEHVE9VA9B673YJAQ/blue%2Bbackground.001.jpg);
}

.v-application a {
    color: white !important;
}

h1 {
    padding-bottom: 10%;
    padding-left: 10%;
    display: flex;
    font-size: 150%;
}

.v-overlay__scrim {
    border-radius: inherit;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: inherit;
    width: 100%;
    will-change: opacity;
    background-color: #97b464 !important;
}

.v-btn--icon.v-size--default .v-icon,
.v-btn--fab.v-size--default .v-icon {
    height: 24px;
    font-size: 50px;
    width: 24px;
    color: black;
}

/* you can modify but modify the movile for different parameters to desktop  */
@media (orientation: landscape) {

    .v-overlay__content {
        margin-right: 1%;
        margin-top: -5%;
    }

    .v-overlay__content .new2 {
        margin-right: 50% !important;
    }

    .v-overlay__content div {
        padding-bottom: 9;
    }

    .v-overlay__content h1 {
        padding-bottom: 10%;
        margin-left: 15%;
        font-size: 160%;
    }

    .v-overlay__content .new {
        column-count: 3;
        margin-top: -5%;
        margin-right: 10%;
        margin-left: -3%;
    }

    .v-overlay__content .v-btn:not(.v-btn--round).v-size--default {
        right: 10%;
        width: 100% !important;
        height: 50% !important;
        font-size: 140% !important;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: 25%;
    }
}

.imp {
    max-width: 600px;
    background-color: white;
}
</style>

<script>
import axios from "axios";
import qs from "qs";
export default {
    created() {
        const url = new URLSearchParams(window.location.search)
        const id = url.get("id")
        if(id){
            this.zohoId = id
        }
        else{
            console.log("ID not found")
        }
        this.verifyUser(id)
    },
    data: () => ({
        zohoPin: null,
        zohoId: null,
        firstName: null,
        loginUserData: null,
        isError: false,
        errorMessage: null,
        absolute: true,
        opacity: 1,
        overlay: false,
        show1: false,
        alert_flag: true,
        waitingLogin: false,
        alertMap: {
            active: false,
            color: "pink",
            content: "Fill in all the boxes"
        },
        // password: 'Password',
        // The params for password
        rules: {
            required: value => !!value || 'Required.',
            min: v => v.length >= 8 || 'Min 8 characters',
            emailMatch: () => (`The email and password you entered don't match`),
        },
    }),

    methods: {
        changeScreen(newValue) {
            this.$store.commit("setCurrentView", newValue);
        },
         setTime(newValue) {
            setTimeout(() => {
                this.alertMap.active = false
            }, 5000)
        },
        async verifyUser(zohoId) {
            try{
                this.alertMap.active = false;
                this.waitingLogin = true;
                let user = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/verify-account`,{
                    zohoId
                });
                this.waitingLogin = false;
                this.firstName = user.data.data.firstName
            }
            catch(error){
                this.waitingLogin = false;
                this.errorMessage = error.response.data.message;
                this.isError = true;
            }
        },
        async loginUser() {
            try {
                if (!!this.zohoPin && this.zohoPin.length >= 4) {
                    this.alertMap.active = false;
                    this.waitingLogin = true;
                    let loginRes = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/verify-pin`, qs.stringify({
                        zohoPin: this.zohoPin,
                        zohoId: this.zohoId
                    }));
                    this.waitingLogin = false;
                    if (!!loginRes.data) {
                        if (loginRes.data.message.includes("Login success")) {
                            this.alertMap.active = false;
                            this.$store.commit("setUserDetails", loginRes.data.data);
                            this.changeScreen('loginPage');
                        } else {
                            this.alertMap.active = true;
                            this.alertMap.content = loginRes.data.message;
                        }
                    }

                }
                else if(this.zohoPin.length < 4){
                    this.alertMap.content = "Pin must be atleast 4 digit";
                    this.alertMap.active = true;
                    this.setTime()
                }
                 else {
                     this.alertMap.content = "Zoho pin is mandatory";
                    this.alertMap.active = true;
                    this.setTime()
                }
            } catch (error) {
                this.waitingLogin = false;
                this.alertMap.active = true;
                this.alertMap.content = "Invalid pin";
            }
        }
    },

    async mounted() {
        var url = window.location.href;
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        let scToken = params.sct;
        if (!!scToken) {
            this.$store.commit("setCurrentView", "password");
            this.$store.commit("setSCToken", scToken);
            console.log(scToken);
        }
        // this.$store.commit("setCurrentView","login");
    },
}
</script>
